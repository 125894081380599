var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-bottom-navigation',{staticClass:"d-flex justify-center align-center rounded-lg _safeAreaInsetBottom",staticStyle:{"box-shadow":"0 4px 8px -4px var(--v-bottomBarColor-base)"},attrs:{"background-color":"bottomBarColor"}},[(_vm.hasRole('guest') && !_vm.appData.additional_options?.includes('Registration'))?void 0:(_vm.hasRole('guest'))?_c('v-btn',{attrs:{"height":"56","color":"bottomBarColor","to":{
      name: 'register',
      params: { source: 'guest' }
    }}},[_c('span',{staticClass:"bottomBarFontColor--text font-weight-regular"},[_vm._v(" "+_vm._s(_vm.$t("AUTH.REGISTER"))+" ")]),_c('v-icon',{attrs:{"color":"bottomBarIconsColor"},domProps:{"textContent":_vm._s('mdi-account-plus-outline')}})],1):(_vm.navigationIncludes('News'))?_c('v-btn',{staticClass:"rounded-lg",attrs:{"height":"48","color":"bottomBarColor","to":{ name: 'news' }}},[_c('span',{staticClass:"bottomBarFontColor--text font-weight-regular"},[_vm._v(" "+_vm._s(_vm.$t("NAVIGATION.NEWS"))+" ")]),(_vm.unreadNews && _vm.unreadNews.length)?_c('v-badge',{attrs:{"overlap":"","small":"","color":"bottomBarFontColor","offset-y":"15"}},[_c('template',{slot:"badge"},[_c('span',{staticClass:"bottomBarColor--text",domProps:{"textContent":_vm._s(_vm.unreadNews.length)}})]),_c('v-icon',{attrs:{"color":"bottomBarIconsColor"},domProps:{"textContent":_vm._s('mdi-bell-outline')}})],2):_c('v-icon',{attrs:{"color":"bottomBarIconsColor"},domProps:{"textContent":_vm._s('mdi-bell-outline')}})],1):_vm._e(),(_vm.notrufTile && _vm.navigationIncludes('Notruf'))?_c('v-btn',{staticClass:"rounded-lg",attrs:{"height":"48","color":"bottomBarColor","to":{
      name: 'tile',
      params: { tile: _vm.notrufTile.id }
    }}},[_c('span',{staticClass:"bottomBarFontColor--text font-weight-regular"},[_vm._v(" "+_vm._s(_vm.$t("NAVIGATION.NOTRUF"))+" ")]),_c('v-icon',{attrs:{"color":"notrufTileColor"},domProps:{"textContent":_vm._s('mdi-phone-in-talk')}})],1):_vm._e(),(_vm.navigationIncludes('Home'))?_c('v-btn',{staticClass:"rounded-lg",attrs:{"height":"48","color":"bottomBarColor","to":{ name: 'home' }}},[_c('span',{staticClass:"bottomBarFontColor--text font-weight-regular"},[_vm._v(" "+_vm._s(_vm.$t("NAVIGATION.HOME"))+" ")]),_c('v-icon',{attrs:{"color":"bottomBarIconsColor"},domProps:{"textContent":_vm._s('mdi-home-outline')}})],1):_vm._e(),(_vm.navigationIncludes('Whiteboard') && !_vm.hasRole('guest'))?_c('v-btn',{staticClass:"rounded-lg",attrs:{"height":"48","color":"bottomBarColor","to":{ name: 'posts' }}},[_c('span',{staticClass:"bottomBarFontColor--text font-weight-regular"},[_vm._v(" "+_vm._s(_vm.$t("WHITEBOARD.TITLE"))+" ")]),_c('v-icon',{attrs:{"color":"bottomBarIconsColor"},domProps:{"textContent":_vm._s('mdi-newspaper-variant-multiple-outline')}})],1):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }